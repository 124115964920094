import { ApplicationConfig, isDevMode } from '@angular/core';
import { NoPreloading, PreloadAllModules, provideRouter, withPreloading } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideServiceWorker } from '@angular/service-worker';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: 'googleTagManagerId', useValue: 'GTM-M3BV5W63', },
    provideRouter(routes, withPreloading(NoPreloading)), provideAnimations(), provideHttpClient(withFetch()),
    {
        provide: RECAPTCHA_V3_SITE_KEY,
        useValue: '6LfRXdkpAAAAAIzL3jaQY-IyWQNl-ZJKOOPdBRRQ',
    },
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    })
]
};
