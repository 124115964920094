<div>
    <header class="header">
        <!-- top header -->
        <div class="header-top">
            <div class="container">
                <div class="header-top-wrapper">
                    <div class="header-top-left">
                        <div class="header-top-contact">                            
                            <ul>
                                
                                <li ><a style="font-size: medium;" href="mailto:onedropcab.in&#64;gmail.com"><i class="far fa-envelopes"></i>
                                    onedropcab.in&#64;gmail.com</a></li>
                                <li><a style="font-size: medium;" href="tel:+919751577744"><i class="far fa-phone-volume"></i> 9751577744</a>
                                </li>
                                <li ><a style="font-size: medium;" href="#"><i class="far fa-alarm-clock"></i> 24 / 7</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="header-top-right">
                        <div class="header-top-link">
                            <a href="#"><i class="far fa-arrow-right-to-bracket"></i> Login</a>
                            <a href="#"><i class="far fa-user-vneck"></i> Register</a>
                        </div>
                        <div class="header-top-social">
                            <span>Follow Us: </span>
                            <a href="#"><i class="fab fa-facebook"></i></a>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                            <a href="#"><i class="fab fa-linkedin"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-navigation">
            <nav class="navbar navbar-expand-lg">
                <div class="container position-relative" >
                    <a class="navbar-brand" href="/" >
                        <div style="background-color: white;">
                            <img  data-src="assets/img/logo/logo_3.svg" class="lazyload"  alt="logo">
                        </div>
                        <div class="show-mobile" style="text-align: right;">
                            <ul>
                                <li><a style="font-size: medium;font-weight: 900;padding: 4px;margin-top: 5px;" href="tel:+9197515 77744"><i class="far fa-phone-volume"></i>9751577744</a>
                            </ul>
                        </div>
                    </a>
                    <div class="mobile-menu-right">
                        
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-mobile-icon"><i class="far fa-bars" style="color: #111111;"></i></span>
                        </button>
                    </div>
                    <div class="collapse navbar-collapse" id="main_nav">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link active home" href="/">Home</a>                            
                            </li>
                            <li class="nav-item "><a class="nav-link about" href="about">About</a></li>
                            
                            <li class="nav-item dropdown">
                                <!-- <a class="nav-link service-all-districts" href="service-all-districts">Service</a> -->
                                <a class="nav-link dropdown-toggle active" href="#" data-bs-toggle="dropdown">Service</a>
                            <ul class="dropdown-menu fade-down">
                                <li><a class="dropdown-item" href="service-all-districts">Popular Drop Taxi Service</a></li>
                                <li><a class="dropdown-item" href="airport-list">Airport Drop Taxi Service</a></li>
                                <li><a class="dropdown-item" href="one-way-drop-taxi-innova">Innova Drop Taxi Service</a></li>
                                <li><a class="dropdown-item" href="service">One Way Taxi</a></li>
                                <!-- <li><a class="dropdown-item" href="index-3.html">Home Page 03</a></li> -->
                            </ul>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link tariff" href="tariff" >Tariff</a>                            
                            </li>
                            <li class="nav-item">
                                <a class="nav-link terms" href="terms" >Our Terms</a>                            
                            </li>
                            <li class="nav-item">
                                <a class="nav-link faq" href="faq" >FAQ</a>                            
                            </li>
                            <li class="nav-item "><a class="nav-link contact" href="contact">Contact</a></li>
                        </ul>
                        <div class="nav-right">
                            
                            <div class="nav-right-btn mt-2">
                                <a href="#" class="theme-btn"><span class="fas fa-taxi"></span>Book A Taxi</a>
                            </div>
                            
                        </div>
                    </div>
                    <div class="search-area">
                        <form action="#">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Type Keyword...">
                                <button type="submit" class="search-icon-btn"><i class="far fa-search"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </nav>
        </div>
    </header>
</div>
