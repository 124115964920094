import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2, VERSION, inject  } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ServiceComponent } from './service/service.component';
import { TermsComponent } from './terms/terms.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { initializeApp } from 'firebase/app';
import { OnExecuteData, RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service, RecaptchaFormsModule, RecaptchaLoaderService, RecaptchaModule, RecaptchaSettings, RecaptchaV3Module } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
declare var $:any;
@Component({
  selector: 'app-root',
  
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, HomeComponent, FooterComponent, AboutComponent, ContactComponent, ServiceComponent, 
  TermsComponent,
  RecaptchaModule,
  RecaptchaV3Module,GoogleTagManagerModule],
 
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers:[
    
     
    
  ],
})
export class AppComponent implements OnInit, AfterViewInit{
   firebaseConfig = {
    apiKey: "AIzaSyAS8Z7DW-CiBuz5HUT85cJnFFutOCratSc",
    authDomain: "onedropcab-in.firebaseapp.com",
    databaseURL: "https://onedropcab-in-default-rtdb.firebaseio.com",
    projectId: "onedropcab-in",
    storageBucket: "onedropcab-in.appspot.com",
    messagingSenderId: "1010180477001",
    appId: "1:1010180477001:web:8000ab235f85249ddaef98",
    measurementId: "G-TX7ZJV544F"
  };
  private subscription: Subscription;

  constructor(@Inject(PLATFORM_ID) private platformId: string, private router: Router, private gtmService: GoogleTagManagerService,private renderer: Renderer2, private recaptchaV3Service: ReCaptchaV3Service,){
    
    // recaptchaV3Service.execute('importantAction')
    // .subscribe((token: string) => {
    //   console.debug(`Token [${token}] generated`);
    // });
    
  }
  ngAfterViewInit(): void {
    
      this.gtmService.googleTagManagerId='GTM-M3BV5W63';
      const gtmTag = {
        event: 'page',
        pageName:this.router.url
    };
  
      this.gtmService.pushTag(gtmTag);  
     if(isPlatformBrowser(this.platformId))
    {
      this.renderer.addClass(document.body, 'no-text-select');
    } 
    const app = initializeApp(this.firebaseConfig);
    this.subscription = this.recaptchaV3Service.onExecute.subscribe((data: OnExecuteData) => {
      // console.log(data);
    });
    
  }
  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  public addTokenLog(message: string, token: string | null) {
    // this.log.push(`${message}: ${this.formatToken(token)}`);
  }

  public onError() {
    console.log(`reCAHPTCHA errored;`);
  }
  ngOnInit(): void {
    // Add the no-text-select class to the body or another container element
   
    
    // const appCheck = initializeAppCheck(
    //   app,
    //   { provider: new ReCaptchaV3Provider('6LfRHnApAAAAAOYlQvAyGDQOrdErMzFIRvSv4vEf'), 
    //     isTokenAutoRefreshEnabled: true
    //   },
    // );
   
  }
  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  title = 'onedropcab.in';
  @HostListener('contextmenu', ['$event'])
  onRightClick(event: Event): void {
    event.preventDefault();
  }
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'F12' || event.keyCode === 123) {
      event.preventDefault();
    }
  }
}
