<div class="preloader">
    <div class="loader-ripple">
        <div></div>
        <div></div>
    </div>
</div>
<div class="row">
    <app-header></app-header>
    <router-outlet ></router-outlet>
    <app-footer></app-footer>
</div>
    
<!-- <re-captcha (resolved)="resolved($event)" siteKey="6LfRHnApAAAAAOYlQvAyGDQOrdErMzFIRvSv4vEf"></re-captcha> -->