import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedModule } from '../shared/Shared.Module';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
declare var $: any;

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements AfterViewInit, OnInit
{

  phone:any;
  data: any={};
  constructor(@Inject(PLATFORM_ID) private platformId: string,  private route: Router, private sanitizer: DomSanitizer)
  {
    this.phone=    this.sanitizer.bypassSecurityTrustUrl('tel:+919751577744');

  }
  ngOnInit() {
  }
  ngAfterViewInit(): void {

    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker(new URL('../header.worker', import.meta.url));
      worker.onmessage = ({ data }) => {
       this.data=data;                            
      };
        worker.postMessage(this.data);  
      
    } else {
      
    }
    if(isPlatformBrowser(this.platformId)){

    
    var routingPath = window.location.pathname;        
    routingPath = routingPath.replace("/",".");    
    if(routingPath.indexOf("/")>-1)
    {
      routingPath = routingPath.split("/")[0];
    }
    if(routingPath == '.'){
      routingPath = '.home';
    }
    $('.navbar-nav').find('.active').removeClass('active');
    $(routingPath).addClass('active');         
  }
  }

}
