<footer class="footer-area">
    <div class="footer-widget">
        <div style="margin-left: 3%; margin-right: 3%;">
            <div class="row footer-widget-wrapper pt-120 pb-70">
                <div class="col-md-1 col-lg-1">
                    
                </div>
                <div class="col-md-2 col-lg-5">
                    <div class="footer-widget-box about-us">
                        
                        
                        <ul class="footer-contact">
                            <li><a href="tel:+919751577744"><i class="far fa-phone"></i>9751577744</a></li>
                            <li><i class="far fa-map-marker-alt"></i>2/193, B Saraswati Garden, <br>Udayapatti, Salem, Tamil Nadu 636140</li>
                            <li><a href="mailto:onedropcab.in&#64;gmail.com"><i
                                        class="far fa-envelope"></i>onedropcab.in&#64;gmail.com</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3 col-lg-2">
                    <div class="footer-widget-box list">
                        <h4 class="footer-widget-title">Quick Links</h4>
                        <ul class="footer-list">
                            <li><a href="about"><i class="fas fa-caret-right"></i> About Us</a></li>
                            <li><a href="service-all-districts"><i class="fas fa-caret-right"></i> Service</a></li>
                            
                            <li><a href="terms"><i class="fas fa-caret-right"></i> Terms Of Service</a></li>
                            <li><a href="contact"><i class="fas fa-caret-right"></i> Contact Us</a></li>
                            <li><a href="service"><i class="fas fa-caret-right"></i>Our Popular Routes</a></li>
                            <li><a href="https://www.google.com/search?hl=en-US&gl=us&q=One+Drop+Cab+-+One+Way+Drop+Taxi+/+Outstation+Cab+Booking+/+Drop+Taxi+/One+Way+Taxi/Airport+Taxi+Service.,+2/193,+B+Saraswati+Garden,+Udayapatti,+Salem,+Tamil+Nadu+636140,+India&ludocid=11444109277744462093&lsig=AB86z5WJ63kPPhTO2qDX4QeHgMWX&ibp=gwp%3B0,7&hl=en&gl=US#lpg=cid:CgIgAQ%3D%3D"><i class="fas fa-caret-right"></i>Rate US (Google) </a></li>
                            
                        </ul>
                    </div>
                </div>
                <div class="col-md-3 col-lg-2">
                    <div class="footer-widget-box list">
                        <h4 class="footer-widget-title">Our Office Locations In Tamilnadu</h4>
                        <ul class="footer-list">
                            <li><a href="contact/chennai"><i class="fas fa-caret-right"></i> One Drop Cab - Chennai</a></li>
                            <li><a href="contact/salem"><i class="fas fa-caret-right"></i> One Drop Cab - Salem</a></li>
                            <li><a href="contact/trichy"><i class="fas fa-caret-right"></i> One Drop Cab - Trichy</a></li>
                            <li><a href="contact/coimbatore"><i class="fas fa-caret-right"></i> One Drop Cab - Coimbatore</a></li>
                            <li><a href="contact/madurai"><i class="fas fa-caret-right"></i> One Drop Cab - Madurai</a></li>
                            <li><a href="contact/vellore"><i class="fas fa-caret-right"></i> One Drop Cab - Vellore</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3 col-lg-2">
                    <div class="footer-widget-box list">
                        <h4 class="footer-widget-title">Our Office Locations Across India</h4>
                        <ul class="footer-list">
                            <li><a href="contact/pondicherry"><i class="fas fa-caret-right"></i> One Drop Cab - Pondicherry</a></li>
                            <li><a href="contact/bangalore"><i class="fas fa-caret-right"></i> One Drop Cab - Bangalore</a></li>
                            <li><a href="contact/tirupathi"><i class="fas fa-caret-right"></i> One Drop Cab - Tirupathi</a></li>
                            <li><a href="contact/hyderabad"><i class="fas fa-caret-right"></i> One Drop Cab - Hyderabad</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright">
        <div class="container">
            <div class="row">
                <div class="col-md-6 align-self-center">
                    <p class="copyright-text">
                        &copy; Copyright &nbsp;<span id="date"></span> <a href="#">&nbsp;  One Drop Cab</a> All Rights Reserved.
                    </p>
                </div>
                <div class="col-md-6 align-self-center">
                    <ul class="footer-social">
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>