<div class="container">
    <h1>Oops! Page Not Found</h1>
    <p><strong>404 Error</strong></p>
    <div style="text-align: center; " >
        <img loading="lazy"  src="../../assets/img/error/404.png" alt="one way drop taxi error page">
    </div>
    
    <p>We're sorry, but it seems like you've ventured into the unknown. The page you're looking for may have taken
        a wrong turn or decided to explore other dimensions.</p>

    <p><strong>What can you do now?</strong></p>
    <ol>
        <li><strong>Navigate Back:</strong> Use your spaceship's navigation system (back button) to return to the
            previous page.</li>
        <li><strong>Explore Our Universe:</strong> Feel free to explore other areas of our website. You might
            discover something amazing!</li>
        <li><strong>Report the Anomaly:</strong> If you believe this is a technical glitch, please contact our
            mission control team at <a href="mailto:onedropcab.in&#64;gmail.com">onedropcab.in&#64;gmail.com</a>.</li>
        <li><strong>Search for Coordinates:</strong> Use the search bar to find what you were looking for. It's like
            entering coordinates in your star map.</li>
    </ol>
    

    <p><em>"To err is human, to explore the cosmos is divine."</em> 🚀✨</p>
</div>