<div class="container pd-50" style="padding: inherit; margin-top: 10vh " *ngIf="data; else error_content">
    <h1>Your Cab Booking Status</h1>

    <div id="status">{{status}}</div>

    <p *ngIf="data.status == 1">Your cab booking is currently in <strong>Booking received</strong> status. We're working to match you with the perfect driver.</p>
    <p *ngIf="data.status == 2">Review and submit the information.</p>
    <div class="row">
           
        <div class="col-lg-12">
            <div class="user-profile-wrapper">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="user-profile-card">
                            <h4 class="user-profile-card-title">Travel Info</h4>
                            <div class="user-profile-form">
                                <form action="#">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Name</label>
                                                <input type="text" class="form-control" disabled [(ngModel)]="data.name" name="name" id="name" placeholder="Your Name">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Phone</label>
                                                <input type="number" class="form-control" disabled [(ngModel)]="data.phone" name="number" id="number" placeholder="Phone Number">
                                            </div>
                                        </div>
                                       
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>From Location</label>
                                                <input type="text" class="form-control" disabled [ngModel]="data.pickUpLoc" name="s-destination" id="s-destination" placeholder="Start Destination">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Pick Up Date & Time</label>
                                                <input type="text" class="date-pick form-control" disabled [ngModel]="data.pickUpDate" name="date-pick" id="date-pick" placeholder="Select Date">
                                            </div>
                                        </div>
                                       
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Selected Cab</label>
                                                <input type="text" class="form-control" disabled [ngModel]="data.cab" name="selected-cab" id="selected-cab" placeholder="Select Date">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Drop Location</label>
                                                <input type="text" class="form-control" disabled [ngModel]="data.dropLoc" name="e-destination" id="e-destination" placeholder="End Destination">
                                            </div>
                                        </div>
                                        <div class="col-md-4" *ngIf="data.travelType=='Round Trip'">
                                            <div class="form-group" >
                                                <label>Return Date & Time</label>
                                                <input type="text" class="date-pick form-control" disabled [ngModel]="data.returnDate" name="date-return" id="date-return" placeholder="Select Date">
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-3" >
                                            <div class="form-group">
                                                <label>Actual Rate</label>
                                                <input placeholder="Driver" name="Driver" disabled [(ngModel)]="data.pricePerKms" id="message" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-3" >
                                            <div class="form-group">
                                                <label>Actual KMS</label>
                                                <a style="background-color: #E9ECEF;" placeholder="Mobile" disabled id="message" class="form-control" href="tel:{{data.assignedDriverMobile}}">
                                                    {{data.kms}}   </a>
                                            </div>
                                        </div>
                                        <div class="col-md-3" >
                                            <div class="form-group">
                                                <label>Total KMS</label>
                                                <input type="number" name="runkms" [(ngModel)]="data.runkms" id="message" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-3" >
                                            <div class="form-group">
                                                <label>Toll</label>
                                                <input  type="number" name="paidtolls"  [(ngModel)]="data.paidtolls" id="message" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                            <label>Driver Beta </label>
                                                <input  type="number" name="driverbeta"  [(ngModel)]="data.driverBeta" id="message" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-3" >
                                            <div class="form-group">
                                                <label>Parking & Permit</label>
                                                <input  type="number" name="parking"  [(ngModel)]="data.parking" id="message" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-3" >
                                            <div class="form-group">
                                                <label>Waiting Charges</label>
                                                <input type="number" name="parking"  [(ngModel)]="data.waitingcharges" id="message" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label></label>
                                                <button mat-button (click)="calc()" style="background: #FAAF00; border: white; border-radius: 8px; width: 90px; height: 40px; font-weight: 700;
                                                font-size: medium;">Calculate</button>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Cost to Receive</label>
                                                <input type="number" name="finalPrice" disabled  [(ngModel)]="data.finalPrice" id="finalPrice" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Commisson Cost</label>
                                                <input type="number" name="commision" disabled  [(ngModel)]="data.commision" id="commision" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label></label>
                                                <button mat-button (click)="update()" style="background: #FAAF00; border: white; border-radius: 8px; width: 90px; height: 40px; font-weight: 700;
                                                font-size: medium;">Update</button>
                                            </div>
                                        </div>
                                    </div>                                  
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>    

</div>
<ng-template #error_content>
    <div class="container" >
        <div id="error-message">Error: Tracking ID not found. Please check your information and try again.</div>

    </div>
</ng-template>
<ng-template #otpContent>
    <h1 mat-dialog-title style="color: #323232;font-weight: 700;
    font-size: medium;">Please enter the OTP</h1>
    
    <div mat-dialog-content style="color: #323232;font-weight: 700;
    font-size: medium;">
      <input [(ngModel)]="otp" type="number" placeholder="otp"/>
    </div>
    <div mat-dialog-actions style="text-align: center;
    position: relative;
    display: block;">
      <button mat-button (click)="verify()" style="background: #FAAF00; border: white; border-radius: 8px; width: 90px; height: 40px; font-weight: 700;
      font-size: medium;
      ">Verify</button>
    </div>
  </ng-template>

  <ng-template #phoneContent>
    <h1 mat-dialog-title style="color: #323232;font-weight: 700;
    font-size: medium;">Please enter the Phone Number</h1>
    
    <div mat-dialog-content style="color: #323232;font-weight: 700;
    font-size: medium;">
      <input [(ngModel)]="phone" type="number" placeholder="Phone"/>
    </div>
    <div mat-dialog-actions style="text-align: center;
    position: relative;
    display: block;">
      <button mat-button (click)="verifyPhone()" style="background: #FAAF00; border: white; border-radius: 8px; width: 90px; height: 40px; font-weight: 700;
      font-size: medium;
      ">Verify</button>
    </div>
  </ng-template>
  <div
id="recaptcha-container"
class="justify-center flex"
siteKey="6LfRXdkpAAAAAIzL3jaQY-IyWQNl-ZJKOOPdBRRQ"
></div>