import { Component } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss'
})
export class ErrorComponent {
constructor(){
this.hideLoader()
}
hideLoader(){

  setTimeout(() => {
      $('.preloader').fadeOut();
  }, 50);
}
}
