
<div style="text-align: center;" class="container py-120">
<h1 mat-dialog-title style="color: #323232;font-weight: 900;
font-size: xxx-large; line-height: 1em;">Booking Confirmed</h1>
<div style="text-align: center;
position: relative;
display: block;">
    <img loading="lazy" src="../../assets/img/success.svg" alt="success" style="height: 100px !important;">
</div> 
<div mat-dialog-content style="color: #323232;font-weight: 900;
font-size: xx-large; margin-top: 20px; line-height: 1em">
  <p>Your Booking Id: {{resultIds}}. You will receive mail shortly.</p>
</div>
<div mat-dialog-actions style="text-align: center;
position: relative;
display: block;
margin-top: 20px;
">
  <button mat-button  (click)="redirectTo('/')" style="background: #FAAF00; border: white; border-radius: 8px; width: 90px; height: 40px; font-weight: 700;
  font-size: medium;
  ">OK</button>
</div>
</div>