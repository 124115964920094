
    <main class="main">

        <!-- hero slider -->
                                 <!-- <div    class="background-image-slider" style="background-color: #1A3F4A; text-align: center;"></div> -->
<!-- 
        <div class="hero-section" *ngIf="dataSliders">
            <div class="hero-slider owl-carousel owl-theme">
                <div class="hero-single" *ngFor="let data of dataSliders"  >
                    <div class="image-container background-image-slider" style="display: contents; background-color: #1A3F4A;">
                        <div class="overlay"></div>
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-lg-9 mx-auto">
                                    <div class="hero-content text-center">
                                        <h6 class="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">{{data.title}}</h6>
                                        <h1 class="hero-title" style="line-height: 1.6; position: relative;" data-animation="fadeInRight" data-delay=".50s" 
                                        [innerHTML]="getData(data.htmltitle)">
                                            
                                        </h1>
                                        <p data-animation="fadeInUp" data-delay=".25s" style=" position: relative;">
                                            {{data.description}}
                                        </p>
                                        <div class="hero-btn justify-content-center" data-animation="fadeInUp"
                                            data-delay="1s">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>                    
                    
                </div>                
            </div>
        </div> -->
        <!-- hero slider end -->

        <app-booking *ngIf="isBrowser"></app-booking>
     

     <!-- about area -->
    
    <!-- about area end -->

 <!-- service area -->
  @defer(){
    <div class="service-area bg" *ngIf="isPageLoadComplete">
        <div class="container" style="    color: #323232;
        font-weight: 600;">
            <div class="row">
                <div class="col-lg-6 mx-auto">
                    <div class="site-heading text-center">
                        <span class="site-title-tagline">Services</span>
                        <h2 class="site-title" style="font-display: swap">{{data.servicetitle}}</h2>
                        <div class="heading-divider"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- @for(dat of data.cars; track dat){
                    <div class="col-md-3 col-lg-3" style="margin: 10px;">
                        <div class="row service-item wow fadeInUp" style="background-color: #ffffff !important;" data-wow-delay=".25s">
                            
                            <div class="service-icon" style="    background: #ffffff !important;
                            left: 1px;
                            right: 10vw;
                            margin-top: 4vh;
                            right: 0px;">
                                <img class="lazyload" priority [id]="dat.id" style="height:60px; width: 70px;" [src]="dat.image"   alt="service">
                            </div>
                            <div class="service-content" style="    left: 80px !important;">
                                <h3 class="service-title">
                                    <a>{{dat.name}}</a>
                                </h3>
                                <p class="service-text">
                                    
                                    One Way : {{dat.traveltypedtl[0].price}} ₹<br>
                                    Round Trip : {{dat.traveltypedtl[1].price}} ₹<br>
                                </p>
                               
                            </div>
                        </div>
                    </div>
                } -->
                
                 <div class="col-md-6 col-lg-4">
                    <div class="service-item wow fadeInUp" data-wow-delay=".25s">
                        <div class="service-img">
                            <img class="lazyload" data-src="assets/img/service/01.webp" style="background: #323232;" alt="service">
                        </div>
                        <div class="service-icon">
                            <img loading="lazy" class="lazyload" data-src="assets/img/icon/taxi-booking-1.svg" alt="taxi">
                        </div>
                        <div class="service-content">
                            <h3 class="service-title">
                                <a href="#">Convenience and flexibility of drop taxi services                                </a>
                            </h3>
                            <p class="service-text">
                                The rise of drop taxi services has revolutionized the way we approach transportation, particularly for travelers. These innovative services offer unparalleled convenience and flexibility, catering to the diverse needs of individuals and groups alike.<br><br>
One of the primary benefits of choosing a drop taxi service is the ability to book your ride in advance. No more frantically searching for an available cab or waiting on the street corner, hoping a taxi will eventually arrive. With a drop taxi service, you can reserve your transportation well ahead of time, ensuring that a vehicle will be ready and waiting for you at your desired pickup location and time. This level of control and predictability can be a game-changer, especially for those with tight schedules or specific transportation requirements.<br><br>
Moreover, drop taxi services often provide a wide range of vehicle options, allowing you to select the one that best suits your needs. Whether you're traveling solo, with a group, or have special accommodations, such as transporting oversized luggage or mobility equipment, these services can accommodate your preferences. This flexibility ensures that your journey is tailored to your unique requirements, making the overall experience more comfortable and stress-free.
                            </p>
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="service-item wow fadeInUp" data-wow-delay=".50s">
                        <div class="service-img">
                            <img class="lazyload" data-src="assets/img/service/02.webp" alt="service1">
                        </div>
                        <div class="service-icon">
                            <img loading="lazy" class="lazyload" data-src="assets/img/icon/city-taxi.svg" alt="service2">
                        </div>
                        <div class="service-content">
                            <h3 class="service-title">
                                <a href="#">Cost-effectiveness of drop taxi services</a>
                            </h3>
                            <p class="service-text">
                                In addition to the convenience and flexibility offered by drop taxi services, they also present a cost-effective transportation solution for travelers. Unlike traditional taxi services, which often charge higher rates during peak hours or in high-demand areas, drop taxi services typically maintain consistent pricing structures.<br><br>
One of the key factors contributing to the cost-effectiveness of drop taxi services is the ability to book your ride in advance. This advance booking allows the service providers to optimize their operations, ensuring efficient route planning and resource allocation. As a result, they can offer competitive rates that are often more affordable than the surge pricing associated with on-demand taxi services.<br><br>
Furthermore, drop taxi services may provide additional cost-saving opportunities, such as discounts for group bookings or loyalty programs for frequent users. These incentives can help travelers stretch their budgets further, making drop taxi services an attractive option for those seeking to maximize their travel spending.
                            </p>
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="service-item wow fadeInUp" data-wow-delay=".75s">
                        <div class="service-img">
                            <img class="lazyload" data-src="assets/img/service/03.webp" alt="service">
                        </div>
                        <div class="service-icon">
                            <img loading="lazy" class="lazyload" data-src="assets/img/icon/airport.svg" alt="service">
                        </div>
                        <div class="service-content">
                            <h3 class="service-title">
                                <a href="#">Safety and security offered by drop taxi services</a>
                            </h3>
                            <p class="service-text">
                                When it comes to transportation, safety and security are paramount concerns for travelers. Drop taxi services have recognized this need and have implemented robust measures to ensure the well-being of their passengers.<br><br>
One of the primary ways drop taxi services prioritize safety is through the rigorous screening and training of their drivers. These professionals undergo thorough background checks, driving assessments, and comprehensive training programs to ensure they possess the necessary skills and knowledge to navigate the roads safely. Additionally, many drop taxi service providers equip their vehicles with advanced safety features, such as GPS tracking, emergency response systems, and regular maintenance checks, to further enhance the security of their passengers.<br><br>
Moreover, drop taxi services often have established protocols and policies in place to address any potential incidents or emergencies that may arise during the journey. Passengers can take comfort in knowing that they have access to dedicated customer support teams and streamlined communication channels, should they need to report any concerns or request assistance. This level of responsiveness and accountability can provide a sense of reassurance, allowing travelers to focus on their journey without unnecessary worries.
                            </p>
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4" *ngIf="isMobileView">
                    <div class="service-item wow fadeInUp" data-wow-delay=".25s">
                        <div class="service-img">
                            <img class="lazyload" data-src="assets/img/service/04.webp" alt="service">
                        </div>
                        <div class="service-icon">
                            <img loading="lazy" class="lazyload" data-src="assets/img/icon/business.svg" alt="service">
                        </div>
                        <div class="service-content">
                            <h3 class="service-title">
                                <a href="#">Time-saving benefits of using drop taxi services</a>
                            </h3>
                            <p class="service-text">
                                Time is a precious commodity, especially for travelers who often have tight schedules and packed itineraries. Drop taxi services recognize the value of time and have designed their services to help passengers maximize their efficiency and productivity.<br><br>
One of the primary ways drop taxi services save time is by eliminating the need to search for and hail a cab on the street or wait for a ride-sharing service to arrive. With a drop taxi service, the vehicle is reserved and ready to go at the scheduled pickup time, allowing passengers to simply step into the car and be on their way. This streamlined process can save valuable minutes, which can add up significantly over the course of a trip.<br><br>
Furthermore, drop taxi services often have the advantage of utilizing specialized algorithms and real-time traffic data to optimize their routes and minimize travel time. By anticipating potential delays and proactively adjusting their routes, these services can help passengers reach their destinations more efficiently, reducing the time spent in transit. This time-saving benefit can be particularly beneficial for travelers who are on tight schedules or need to make multiple stops during their journey.
                            </p>                        
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4" *ngIf="isMobileView">
                    <div class="service-item wow fadeInUp" data-wow-delay=".50s">
                        <div class="service-img">
                            <img class="lazyload" data-src="assets/img/service/05.webp" alt="service">
                        </div>
                        <div class="service-icon">
                            <img loading="lazy" class="lazyload" data-src="assets/img/icon/taxi-2.svg" alt="service">
                        </div>
                        <div class="service-content">
                            <h3 class="service-title">
                                <a href="#">Availability and accessibility of drop taxi services</a>
                            </h3>
                            <p class="service-text">
                                The widespread availability and accessibility of drop taxi services have contributed to their growing popularity among travelers. These services are no longer limited to major metropolitan areas but have expanded their reach to a wide range of destinations, catering to the needs of both urban and rural communities.<br><br>
One of the key factors driving the accessibility of drop taxi services is the advancements in mobile technology and the proliferation of user-friendly booking platforms. Travelers can now easily access and book these services through their smartphones or other digital devices, regardless of their location. This convenience allows individuals to plan their transportation needs seamlessly, even when they are on the go or in unfamiliar areas.<br><br>
Moreover, drop taxi services often have a diverse fleet of vehicles, ranging from standard sedans to larger vans or SUVs, to accommodate the needs of various travelers. This diversity in vehicle options ensures that passengers can find a suitable mode of transportation that meets their specific requirements, whether they are traveling solo, with a group, or with additional luggage or mobility equipment. The availability of these customized transportation solutions enhances the overall accessibility and inclusivity of drop taxi services.
                            </p>
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4" *ngIf="isMobileView">
                    <div class="service-item wow fadeInUp" data-wow-delay=".75s">
                        <div class="service-img">
                            <img class="lazyload" data-src="assets/img/service/06.webp" alt="service">
                        </div>
                        <div class="service-icon">
                            <img loading="lazy" class="lazyload" data-src="assets/img/icon/taxi.svg" alt="service">
                        </div>
                        <div class="service-content">
                            <h3 class="service-title">
                                <a href="#">Environmental benefits of choosing drop taxi services</a>
                            </h3>
                            <p class="service-text">
                                As the world becomes increasingly conscious of the environmental impact of our actions, the transportation industry has also come under scrutiny. In this context, the rise of drop taxi services has presented an opportunity to address some of the environmental concerns associated with traditional transportation methods.<br><br>
One of the primary environmental benefits of choosing a drop taxi service is the potential reduction in carbon emissions. By optimizing their routes and fleet management, drop taxi service providers can minimize the number of vehicles on the road and the overall distance traveled, thereby reducing the carbon footprint associated with each trip. This eco-friendly approach not only benefits the environment but also contributes to the sustainability of the transportation sector as a whole.<br><br>
Furthermore, many drop taxi service providers are investing in the adoption of eco-friendly vehicles, such as hybrid or electric models, further enhancing their environmental credentials. These vehicles not only emit lower levels of greenhouse gases but also help to reduce noise pollution, creating a more pleasant travel experience for passengers and the surrounding communities.
                            </p>                        
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
  }

<!-- service area end-->
       

    
        <!-- counter area -->
        <!-- <div class="counter-area" *ngIf="isPageLoadComplete">
            <div class="container">
                <div class="counter-wrapper">
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-box">
                                <div class="icon">
                                    <img loading="lazy" src="assets/img/icon/taxi-1.svg" alt="">
                                </div>
                                <div>
                                    <span class="counter" data-count="+" data-to="2000" data-speed="3000">2000</span>
                                    <h6 class="title">+ Available Taxi </h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-box">
                                <div class="icon">
                                    <img loading="lazy" src="assets/img/icon/happy.svg" alt="">
                                </div>
                                <div>
                                    <span class="counter" data-count="+" data-to="20000" data-speed="3000">20000</span>
                                    <h6 class="title">+ Happy Clients</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-box">
                                <div class="icon">
                                    <img loading="lazy" src="assets/img/icon/driver.svg" alt="">
                                </div>
                                <div>
                                    <span class="counter" data-count="+" data-to="2000" data-speed="3000">2000</span>
                                    <h6 class="title">+ Our Drivers</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-box">
                                <div class="icon">
                                    <img loading="lazy" src="assets/img/icon/trip.svg" alt="">
                                </div>
                                <div>
                                    <span class="counter" data-count="+" data-to="100001" data-speed="3000">100001</span>
                                    <h6 class="title">+ Road Trip Done</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- counter area end -->


        <!-- feature area -->
        <div class="feature-area feature-bg py-120" *ngIf="isPageLoadComplete">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 mx-auto">
                        <div class="site-heading text-center">
                            <span class="site-title-tagline">Feature</span>
                            <h2 class="site-title text-white">Our Awesome Feature</h2>
                            <div class="heading-divider"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-3">
                        <div class="feature-item wow fadeInUp" data-wow-delay=".25s">
                            <div class="feature-icon">
                                <img loading="lazy" class="lazyload" data-src="assets/img/icon/taxi-safety.svg" alt="service">
                            </div>
                            <div class="feature-content">
                                <h4>How to book a drop taxi service?</h4>
                                <p>Booking a drop taxi service for your next trip is a straightforward process that can be easily navigated through various platforms and channels. The convenience of these services extends beyond the actual transportation experience, as the booking process itself is designed to be user-friendly and efficient.<br><br>
                                    One of the most common ways to book a drop taxi service is through mobile applications or online booking platforms. These digital tools provide a seamless interface where travelers can input their pickup and drop-off locations, select their desired vehicle type, and choose the preferred date and time for their journey. Many of these platforms also offer additional features, such as the ability to track the vehicle's progress, communicate with the driver, and even receive real-time updates on the estimated arrival time.<br><br>
                                    Alternatively, some drop taxi service providers offer the option to book directly through their website or by contacting their customer service representatives. This approach can be particularly useful for those who prefer a more personalized booking experience or have specific requirements that may not be easily accommodated through the automated booking process. Regardless of the method chosen, the key is to plan ahead and secure your transportation well in advance to ensure a smooth and stress-free travel experience.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="feature-item mt-lg-5 wow fadeInDown" data-wow-delay=".25s">
                            <div class="feature-icon">
                                <img loading="lazy" class="lazyload" data-src="assets/img/icon/pickup.svg" alt="service">
                            </div>
                            <div class="feature-content">
                                <h4>Tips for a smooth and enjoyable drop taxi experience                                </h4>
                                <p>Convenience and flexibility of drop taxi services<br><br>To maximize the benefits of a drop taxi service and ensure a seamless travel experience, it's essential to keep a few tips in mind. By following these guidelines, you can optimize your journey and fully embrace the convenience and comfort that these services have to offer.<br><br>
                                    First and foremost, it's crucial to book your drop taxi service well in advance. This not only guarantees the availability of a vehicle at your desired time but also allows you to take advantage of any early booking discounts or promotional offers. Additionally, being proactive in your booking can help you secure the specific vehicle type or seating configuration that best suits your needs.<br><br>
                                    Another important tip is to provide clear and accurate information during the booking process. This includes details such as your exact pickup and drop-off locations, the number of passengers, and any special requirements or accommodations you may need. By furnishing the service provider with comprehensive details, you can ensure that they are well-prepared to cater to your specific needs and deliver a seamless travel experience.
                                    </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="feature-item wow fadeInUp" data-wow-delay=".25s">
                            <div class="feature-icon">
                                <img loading="lazy" class="lazyload" data-src="assets/img/icon/money.svg" alt="service">
                            </div>
                            <div class="feature-content">
                                <h4>Conclusion: Why drop taxi services are the ideal choice for your next trip
                                </h4>
                                <p>Cost-effectiveness of drop taxi services.<br><br>In conclusion, the benefits of choosing a drop taxi service for your next trip are undeniable. From the unparalleled convenience and flexibility to the cost-effectiveness and time-saving advantages, these innovative transportation solutions have transformed the way travelers approach their journeys.<br><br>
                                    By opting for a drop taxi service, you can enjoy the peace of mind that comes with a guaranteed pickup and drop-off, as well as the assurance of safety and security throughout your journey. The widespread availability and accessibility of these services further enhance their appeal, allowing you to access reliable transportation in a wide range of destinations.<br><br><br>
                                    Moreover, the environmental benefits of choosing a drop taxi service, such as the reduction in carbon emissions and the adoption of eco-friendly vehicles, align with the growing global consciousness towards sustainable travel practices. This commitment to environmental responsibility adds an extra layer of value to the drop taxi experience, making it an increasingly attractive option for eco-conscious travelers.
                                    
                                    </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="feature-item mt-lg-5 wow fadeInDown" data-wow-delay=".25s">
                            <div class="feature-icon">
                                <img loading="lazy" class="lazyload" data-src="assets/img/icon/support.svg" alt="service">
                            </div>
                            <div class="feature-content">
                                <h4>24/7 Support</h4>
                                <p>The benefits of choosing a drop taxi service are numerous. Not only do you save time and avoid the hassle of searching for a cab, but you also get the peace of mind of reaching your destination safely and comfortably. Availability and accessibility of drop taxi services<br><br>Furthermore, it's advisable to familiarize yourself with the drop taxi service's policies and procedures, particularly regarding cancellations, modifications, and any additional fees or charges. Understanding these guidelines can help you navigate the booking process with confidence and avoid any unexpected surprises during your journey. <br><br> Whether you're embarking on a business trip, a family vacation, or a solo adventure, a drop taxi service can provide the seamless and stress-free transportation solution you've been seeking. By following the tips and insights outlined in this article, you can ensure a smooth and enjoyable drop taxi experience, allowing you to focus on the true purpose of your journey – creating lasting memories and exploring the world around you.<br><br> So, why wait? Embrace the convenience, flexibility, and peace of mind that a drop taxi service has to offer, and make your next trip a truly remarkable and hassle-free experience. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <!-- choose area -->
        <!-- <div class="choose-area py-4" *ngIf="isPageLoadComplete">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="choose-content">
                            <div class="site-heading wow fadeInDown mb-4" data-wow-delay=".25s">
                                <span class="site-title-tagline text-white justify-content-start">
                                    <i class="flaticon-drive"></i> Why Choose Us
                                </span>
                                <h2 class="site-title text-white mb-10">We are dedicated <span>to provide</span> quality service</h2>
                                <p class="text-white">
                                    Elevate your travel experience with our commitment to quality service. Our cabs are not just rides; they are journeys marked by professionalism, comfort, and reliability. Immerse yourself in the excellence of our service, where every ride is a testament to our dedication to your satisfaction. Choose quality – choose our cab service for a journey that exceeds expectations. Book now and experience the difference!
                                </p>
                            </div>
                            <div class="choose-img wow fadeInUp" data-wow-delay=".25s">
                                <img priority="low" [src]="data!.choose1" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="choose-content-wrapper wow fadeInRight" data-wow-delay=".25s">
                            <div class="choose-item">
                                <span class="choose-count">01</span>
                                <div class="choose-item-icon">
                                    <img loading="lazy" class="lazyload" data-src="assets/img/icon/taxi-1.svg" alt="">
                                </div>
                                <div class="choose-item-info">
                                    <h3>Best Quality Taxi</h3>
                                    <p>Experience the epitome of quality with our premium taxi service. Impeccably maintained vehicles, professional drivers, and a commitment to excellence define our service. We take pride in delivering the best – from safety to comfort. Choose the assurance of the best quality tax service for a journey that exceeds your expectations. Your satisfaction is our standard. Book now and indulge in top-notch transportation!</p>
                                </div>
                            </div>
                            <div class="choose-item ms-lg-5">
                                <span class="choose-count">02</span>
                                <div class="choose-item-icon">
                                    <img  loading="lazy" src="assets/img/icon/driver.svg" alt="">
                                </div>
                                <div class="choose-item-info">
                                    <h3>Expert Drivers</h3>
                                    <p>Experience the difference with our seasoned drivers. Well-versed in navigating the roads, our drivers ensure a smooth and safe journey. Benefit from their local knowledge, professionalism, and commitment to making your ride comfortable. Travel with confidence – experience the expertise of our drivers</p>
                                </div>
                            </div>
                            <div class="choose-item mb-lg-0">
                                <span class="choose-count">03</span>
                                <div class="choose-item-icon">
                                    <img loading="lazy" class="lazyload" data-src="assets/img/icon/taxi-location.svg" alt="">
                                </div>
                                <div class="choose-item-info">
                                    <h3>Many Locations</h3>
                                    <p>Connect seamlessly across multiple locations with our expansive taxi service. We're not just a ride; we're your travel companion in various places. Enjoy the convenience of our services spanning across different locations, ensuring reliable transportation wherever you go. Your trusted taxi service, no matter the destination – book now for a consistent and convenient ride experience across many locations!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- choose area end -->


        

        <!-- testimonial area -->
        <app-testimonial *ngIf="isPageLoadComplete"></app-testimonial>
        <!-- testimonial area end -->

        <app-all-district [isDistrictOnly]="true"></app-all-district>
        <!-- cta-area -->
        <!-- <div class="cta-area" *ngIf="isPageLoadComplete">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7 text-center text-lg-start">
                        <div class="cta-text cta-divider">
                            <h1>Unlock Easy and Budget-Friendly Transportation – Your Journey Begins Here!
                            </h1>
                            <p>Ready to experience seamless and affordable travel? Look no further! Booking your cab is now a breeze with our user-friendly platform. Enjoy the simplicity of reservation and the affordability of our services. Your comfortable journey awaits – Book your cab now!</p>
                        </div>
                    </div>
                    <div class="col-lg-5 text-center text-lg-end">
                        <div class="mb-20">
                            <a href="#" class="cta-number"><i class="far fa-headset"></i>9751577744</a>
                        </div>
                        <div class="cta-btn">
                            <a href="#" class="theme-btn">Book Your Cab<i class="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- cta-area end -->


       

        <!-- partner -->
        <!-- <div class="partner pt-80 pb-80">
            <div class="container">
                <div class="partner-slider owl-carousel owl-theme">
                    <div class="partner-item">
                        <div class="partner-img">
                            <img loading="lazy" src="assets/img/partner/car-etios.png" alt="">
                        </div>
                    </div>
                    <div class="partner-item">
                        <div class="partner-img">
                            <img loading="lazy" src="assets/img/partner/car-swift.png" alt="">
                        </div>
                    </div>
                    <div class="partner-item">
                        <div class="partner-img">
                            <img loading="lazy" src="assets/img/partner/03.webp" alt="">
                        </div>
                    </div>
                    <div class="partner-item">
                        <div class="partner-img">
                            <img loading="lazy" src="assets/img/partner/01.png" alt="">
                        </div>
                    </div>
                    <div class="partner-item">
                        <div class="partner-img">
                            <img loading="lazy" src="assets/img/partner/02.webp" alt="">
                        </div>
                    </div>
                    <div class="partner-item">
                        <div class="partner-img">
                            <img loading="lazy" src="assets/img/partner/03.webp" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- partner end-->


       
    </main>

  
      