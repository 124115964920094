import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { SharedModule } from '../shared/Shared.Module';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { TravelModel } from '../model/travel.model';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
import { TestimonialComponent } from '../testimonial/testimonial.component';
import { Validators } from '@angular/forms';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MediaMatcher } from '@angular/cdk/layout';
import { BookingComponent } from '../booking/booking.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AppConstants } from '../shared/app.constants';
import { AllDistrictComponent } from '../all-district/all-district.component';

declare var $: any;

declare const WOW: any;

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [SharedModule, TestimonialComponent, BookingComponent, AllDistrictComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})

export class HomeComponent implements OnInit,AfterViewInit, OnDestroy{
  SEOKey="Outstation One way Drop Taxi, One way Taxi, Drop Taxi, Drop Cabs, Drop Taxi, One way Cabs, One Way Taxi Service, One Way Drop Taxi Service, One Way Drop Cab Service, Cab Service, Taxi Service, Taxi for Rent, Best taxi service, Cabs, Taxi for OutStation";
  SEODescription= "Discover unparalleled convenience with our One Drop Cab Service, offering reliable and affordable one-way transportation across South India. Our professional drivers and well-maintained fleet ensure punctual and stress-free travel, making OneDropCab the preferred choice for your journey. Book now for a seamless and comfortable experience at the best rates. Your journey begins with us!";
  SEOTitle="The Benefits of Choosing a Drop Taxi Service for Your Next Trip | One Drop Cab"
  public sanitizer: DomSanitizer;
  cars:any=[];
  reviews:any=[];
  reviewRating= [];
  travelSelectedPos=0;
  isBrowser: boolean = false;
  errorMessage: string;
  
  
  dataSliders:any[]=[];

  isPageLoadComplete: boolean=false;
  data:any={
    
  };
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    autoWidth:true,

    // navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  @ViewChild('owlCarousel')owlCarousel: any;
  isMobileView: boolean;
  constructor(@Inject(PLATFORM_ID) private platformId: string, private cdr: ChangeDetectorRef, private dialog: MatDialog,private sanitize:DomSanitizer,private el: ElementRef, 
    private zone: NgZone, private metaService: Meta, private titleService: Title, @Inject(DOCUMENT) private doc, private gtmService: GoogleTagManagerService
    ){
      this.createLinkAndMetas(this.SEOTitle, this.SEOKey, this.SEODescription, true, false);
      if(isPlatformBrowser(this.platformId))
      {
        
        this.sanitizer  = sanitize;
        this.isBrowser = true;
      }
    
  }
  getData(data){
    return this.sanitizer!.bypassSecurityTrustHtml(data)
  }
  ngOnInit(): void {
    
    
    
  }
  private initializeCountToOnScroll() {
    const element = $(this.el.nativeElement).find('.counter');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.zone.run(() => {
              $(this.el.nativeElement).find('.counter').countTo();
            });
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    observer.observe(element[0]);
  }
  ngOnDestroy() {
    if(isPlatformBrowser(this.platformId))
    {
      window.removeEventListener('resize', this.checkScreenSize);
    }
  }
  private checkScreenSize = () => {
    this.isMobileView = window.innerWidth >= 600;
    this.cdr.detectChanges(); // Ensure Angular detects the changes
  };
 
  ngAfterViewInit(): void {
    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker(new URL('../background.worker', import.meta.url));
      worker.onmessage = ({ data }) => {
       this.data=data.data;
      //  const link = document.createElement('link');
      //  link.rel = 'stylesheet';
      //  link.href = data.url;

       // Append the link element to the head to load the CSS
      //  document.head.appendChild(link);
console.log(this.data);
        this.isPageLoadComplete=true;
          this.checkScreenSize();
          this.cdr.detectChanges();
          window.addEventListener('resize', this.checkScreenSize);
    
          // setTimeout(() => {
          //   this.initializeCountToOnScroll();  
          // }, 1000);
          
          
          
      };
        worker.postMessage(this.data);  
      
    } else {
      
    }
    
    var json = this.getSliders();
    if(isPlatformBrowser(this.platformId))
    {
      $(document).on('ready', function () {
          $("[data-background]").each(function () {
              $(this).css("background-image", "url(" + $(this).attr("data-background") + ")");
          });
      });


      // navbar search 
      $('.search-btn').on('click', function() {
          $('.search-area').toggleClass('open');
      });


      
    //   $('.partner-slider').owlCarousel({
    //     loop: true,
    //     margin: 25,
    //     nav: false,
    //     dots: false,
    //     autoplay: true,
    //     responsive: {
    //         0: {
    //             items: 2
    //         },
    //         600: {
    //             items: 3
    //         },
    //         1000: {
    //             items: 6
    //         }
    //     }
    // });
    // $(".popup-gallery").magnificPopup({
    //   delegate: '.popup-img',
    //   type: 'image',
    //   gallery: {
    //       enabled: true
    //   },
    // });

    // $(".popup-youtube, .popup-vimeo, .popup-gmaps").magnificPopup({
    //   type: "iframe",
    //   mainClass: "mfp-fade",
    //   removalDelay: 160,
    //   preloader: false,
    //   fixedContentPos: false
    // });
    $(window).scroll(function () {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                $("#scroll-top").addClass('active');
            } else {
                $("#scroll-top").removeClass('active');
            }
        });

        $("#scroll-top").on('click', function () {
            $("html, body").animate({ scrollTop: 0 }, 1500);
            return false;
        });
        // if ($('#countdown').length) {
        //   $('#countdown').countdown('2028/01/30', function (event) {
        //       $(this).html(event.strftime('' + '<div class="row">' + '<div class="col countdown-single">' + '<h2 class="mb-0">%-D</h2>' + '<h5 class="mb-0">Day%!d</h5>' + '</div>' + '<div class="col countdown-single">' + '<h2 class="mb-0">%H</h2>' + '<h5 class="mb-0">Hours</h5>' + '</div>' + '<div class="col countdown-single">' + '<h2 class="mb-0">%M</h2>' + '<h5 class="mb-0">Minutes</h5>' + '</div>' + '<div class="col countdown-single">' + '<h2 class="mb-0">%S</h2>' + '<h5 class="mb-0">Seconds</h5>' + '</div>' + '</div>'));
        //   });
          
        // }
  }





  
  }
  
  
  
  async getSliders(){
    
    // const realTimeDb = getDatabase();
    //   let  dataRef = query(ref(realTimeDb, 'Sliders'));        
    //   get(dataRef).then((snapshot) => {
    //     if (snapshot.exists()) {
          // this.dataSliders=snapshot.val();

          
      
    //     } else {
          
    //     }
    //   }).catch((error) => {
    //     // console.error(error);
    //   });

    // let jsonPayload={"action":"fetchsliders"
    //       }
    //     fetch(AppConstants.APP_URL,{
    //       method:'POST',
    //       body:JSON.stringify(jsonPayload)}).then((response) => response.json())
    //     .then((resData) =>{ 
    //       this.dataSliders = resData;
    //       setTimeout(() => {

    //         $('.sidebar-btn').on('click', function() {
    //           $('.sidebar-popup').addClass('open');
    //           $('.sidebar-wrapper').addClass('open');
    //       });
    //       $('.close-sidebar-popup, .sidebar-popup').on('click', function() {
    //           $('.sidebar-popup').removeClass('open');
    //           $('.sidebar-wrapper').removeClass('open');
    //       });
        
    //         $('.hero-slider').owlCarousel({
    //           loop: true,
    //           nav: true,
    //           dots: false,
    //           margin: 0,
    //           autoplay: true,
    //           autoplayHoverPause: true,
    //           autoplayTimeout: 70000,
    //           items: 1,
    //           navText: [
    //               "<i class='far fa-long-arrow-left'></i>",
    //               "<i class='far fa-long-arrow-right'></i>"
    //           ],
        
    //           onInitialized: function(event) {
    //           var $firstAnimatingElements = $('.owl-item').eq(event.item.index).find("[data-animation]");
    //           doAnimations($firstAnimatingElements);
    //           },
        
    //           onChanged: function(event){
    //           var $firstAnimatingElements = $('.owl-item').eq(event.item.index).find("[data-animation]");
    //           doAnimations($firstAnimatingElements);
    //           }
    //       });
    //       function doAnimations(elements) {
    //         var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
    //         elements.each(function () {
    //           var $this = $(this);
    //           var $animationDelay = $this.data('delay');
    //           var $animationDuration = $this.data('duration');
    //           var $animationType = 'animated ' + $this.data('animation');
    //           $this.css({
    //             'animation-delay': $animationDelay,
    //             '-webkit-animation-delay': $animationDelay,
    //                     'animation-duration': $animationDuration,
    //                     '-webkit-animation-duration': $animationDuration,
    //           });
    //           $this.addClass($animationType).one(animationEndEvents, function () {
    //             $this.removeClass($animationType);
    //           });
    //         });
    //       }
    //       $(".preloader").fadeOut("slow");
    //       }, 50);
    //     })
   }
  
  createLinkAndMetas(title: string, keywords: string, description: string, isCanonicalRequired: boolean, isLocation: boolean) {

    this.titleService.setTitle(title);
    var keys = "";
    var prekeys=keywords.split(",");
    if(isLocation)
    {
      var loc = this.doc.URL.split("/")[4];
      for(var i=0;i< prekeys.length; i++)
        {      
          keys+=prekeys[i]+" in "+loc+", "
        }
        keys = keys.substring(0,keys.length-1);
    }
    else{
      keys  = keywords
    }
   
    keys = keys.toLocaleLowerCase();
    this.metaService.updateTag(              
    {
      name: 'description', content: description
    })
    this.metaService.updateTag(              
    {
      name: 'keywords', content: keys
    })
    
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:image', content: window.location.origin+'/assets/img/logo/logo1.png' });
    this.metaService.updateTag({ property: 'og:image:alt', content:title });
    this.metaService.updateTag({ property: 'twitter:title', content: title });
    this.metaService.updateTag({ property: 'twitter:description', content: description });
    this.metaService.updateTag({ property: 'twitter:image', content: window.location.origin+'/assets/img/logo/logo1.png' });
    this.metaService.updateTag({ property: 'twitter:url', content:window.location.origin});
    this.metaService.updateTag({ property: 'twitter:site', content:'One Drop Cab'});
    if(isCanonicalRequired &&isPlatformBrowser(this.platformId))
    {
      const link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
    }
 }
}
