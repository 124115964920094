import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecaptchaVerifier, getAuth, signInWithEmailAndPassword, signInWithPhoneNumber } from 'firebase/auth';
import { collection, doc, getDoc, getFirestore } from 'firebase/firestore';
import { SharedModule } from '../shared/Shared.Module';
import * as CryptoJS from 'crypto-js';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'app-tracking',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './tracking.component.html',
  styleUrl: './tracking.component.scss'
})
export class TrackingComponent implements OnInit{
status: any;
data:any;
keySize = 256;
ivSize = 128;
iterations = 100;
password = "Password@1234";
@ViewChild('otpContent') otpContent: TemplateRef<any>;
@ViewChild('phoneContent') phoneContent: TemplateRef<any>;
otp: any;
confirmationResult: any ;
trackingId: any;
phone:any;
dbPhone: any;
verifier: any;
 constructor(private route: ActivatedRoute, private matDialog:MatDialog){
 
 }
  ngOnInit(): void {
    this.route.params.subscribe((res:any)=>{
      // this.state  = res.state;
      let id=this.decrypt(res.trackingId.replace("__","/")).toString(CryptoJS.enc.Utf8)
      // console.log(id);
      this.trackingId = id.toString().split("::")[0];
      this.dbPhone = id.toString().split("::")[1]
      this.tracking();
    })
  }
tracking(){
  const auth = getAuth();

  this.verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
    size: 'invisible',
    'callback': (response) => {
      // console.log(response)
      
      this.openPhoneDialog();
      this.hideLoader();
    },
    'expired-callback': () => {      
    }
  });
  this.verifier.verify();
 }
 verifyPhone(){
  if(this.phone && this.phone.toString().length==10 && this.phone== this.dbPhone)
  {
    this.sendOTP( this.verifier, "+91"+this.phone)
  }
  else{

  }
 }
 openPhoneDialog(){
  const dialogRef = this.matDialog.open(this.phoneContent, {
    disableClose:true,
    backdropClass: 'custom-dialog-overlay',
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result)      
    {
      
    }
  });
 }
 openOTPDialog(): void {
  
  const dialogRef = this.matDialog.open(this.otpContent, {
    disableClose:true,
    backdropClass: 'custom-dialog-overlay',
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result)      
    {
      
    }
  });
  
}
verify()
{
  this.matDialog.closeAll();
  $('.preloader').show();
  this.confirmationResult.confirm(this.otp).then(async (res) => {
    // User signed in successfully.
    const user = res.user;
    // console.log(user);
  var result = doc(getFirestore(),"onedropcab.in",this.trackingId);
  var document =await getDoc(result);
  if(document.exists())
  {
    this.data=document.data();
    this.hideLoader();
  }
  else{
    this.hideLoader();
  }
  }).catch((error) => {
    // User couldn't sign in (bad verification code?)
    // ...
  });
}
 sendOTP( recaptchaVerifier, phone){
  try{
    signInWithPhoneNumber(getAuth(), phone, recaptchaVerifier)
    .then(async (confirmationResult) => 
    {
      // console.log(confirmationResult);
      this.confirmationResult = confirmationResult;
      this.openOTPDialog();
      this.hideLoader()
    },
    err=>{
      // console.log(err);
      this.hideLoader()
    }
    
     );
    
  }catch(err){
    // console.error(err);
  }
 }
 decrypt (transitmessage) {
  var salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
  var iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32))
  var encrypted = transitmessage.substring(64);
  
  var key = CryptoJS.PBKDF2(this.password, salt, {
      keySize: this.keySize/32,
      iterations: this.iterations
    });

  var decrypted = CryptoJS.AES.decrypt(encrypted, key, { 
    iv: iv, 
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
    
  })
  return decrypted;
}
 hideLoader(){

  setTimeout(() => {
      $('.preloader').fadeOut();
  }, 50);
}

}
