import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ServiceComponent } from './service/service.component';
import { TermsComponent } from './terms/terms.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { AllDistrictComponent } from './all-district/all-district.component';
import { TariffComponent } from './tariff/tariff.component';
import { ErrorComponent } from './error/error.component';
import { CityComponent } from './city/city.component';
import { FAQComponent } from './faq/faq.component';
import {  CabsInnovaComponent } from './cabs-innova/cabs-innova.component';
import { CabServiceDetailsComponent } from './cab-service-details/cab-service-details.component';
import { BookingComponent } from './booking/booking.component';
import { BookingSuccessComponent } from './booking-success/booking-success.component';
import { TrackingComponent } from './tracking/tracking.component';
import { DriverComponent } from './driver/driver.component';
import { AirportServiceComponent } from './airport-service/airport-service.component';
import { AirportComponent } from './airport/airport.component';
import { AirportListComponent } from './airport-list/airport-list.component';

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'about',
        loadComponent:()=>import("./about/about.component").then((m)=>m.AboutComponent)        
    },
    {
        path: 'contact',
        loadComponent:()=>import("./contact/contact.component").then((m)=>m.ContactComponent)                
    },
    {
        path: 'contact/:office',
        loadComponent:()=>import("./contact/contact.component").then((m)=>m.ContactComponent)               
    },
    {
        path: 'service-areas/city/:districts/:route',
        loadComponent:()=>import("./service-details/service-details.component").then((m)=>m.ServiceDetailsComponent)               
    },
    {
        path: 'service-areas/airport-service/:districts/:route',
        loadComponent:()=>import("./airport/airport.component").then((m)=>m.AirportComponent)        
    },
    {
        path: 'cab-service/:cabtype/:route',
        loadComponent:()=>import("./cab-service-details/cab-service-details.component").then((m)=>m.CabServiceDetailsComponent)               
    },
    
    {
        path: 'service/:city',
        loadComponent:()=>import("./service/service.component").then((m)=>m.ServiceComponent)               
    },   
    {
        path: 'airport-list',
        loadComponent:()=>import("./airport-list/airport-list.component").then((m)=>m.AirportListComponent)               
    },
    {
        path: 'airport-service',
        loadComponent:()=>import("./airport-service/airport-service.component").then((m)=>m.AirportServiceComponent)               
    },
    {
        path: 'airport-service/:airportname',
        loadComponent:()=>import("./airport-service/airport-service.component").then((m)=>m.AirportServiceComponent)               
    },
    {
        path: 'terms',
        loadComponent:()=>import("./terms/terms.component").then((m)=>m.TermsComponent)               
    },    
    {
        path: 'service-all-districts',
        loadComponent:()=>import("./all-district/all-district.component").then((m)=>m.AllDistrictComponent)               
    },    
    {
        path: 'district/:district',
        loadComponent:()=>import("./district/district.component").then((m)=>m.DistrictComponent)               
    }
    ,    
    {
        path: 'tariff',
        loadComponent:()=>import("./tariff/tariff.component").then((m)=>m.TariffComponent)               
    },
    {
        path: 'service',
        loadComponent:()=>import("./citylist/citylist.component").then((m)=>m.CitylistComponent)               
    },
    {
        path: 'one-way-drop-taxi/:city',
        loadComponent:()=>import("./city/city.component").then((m)=>m.CityComponent),        
        data:{
            title: "Title Page",
            description: "Description Meta Tag Content",
            ogUrl: "your og url",
            keywords: " Keywords Meta Tag Content "
        }
    },
    {
        path: 'one-way-drop-taxi-innova',
        loadComponent:()=>import("./cabs-innova/cabs-innova.component").then((m)=>m.CabsInnovaComponent)               
    },
    {
        path: 'faq',
        loadComponent:()=>import("./faq/faq.component").then((m)=>m.FAQComponent)               
    },
    {
        path:'bookingSuccess',
        component: BookingSuccessComponent

    },
    {
        path:'tracking/:trackingId',
        component: TrackingComponent

    },
    {
        path:'driver/:trackingId',
        component: DriverComponent

    },
    {
        path: '**',
        component: ErrorComponent
    },
    {
        path: 'error',
        component: ErrorComponent
    }
];
