import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedModule } from '../shared/Shared.Module';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import { isPlatformBrowser } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-booking-success',
  standalone: true,
  imports: [SharedModule, GoogleTagManagerModule],
  
  templateUrl: './booking-success.component.html',
  styleUrl: './booking-success.component.scss'
})
export class BookingSuccessComponent implements AfterViewInit {
resultIds: string="";
constructor(@Inject(PLATFORM_ID) private platformId: string,private router: Router, private route: ActivatedRoute, private gtmService: GoogleTagManagerService){

  if(isPlatformBrowser(this.platformId))
  {
    this.resultIds= this.router.getCurrentNavigation().extras?.state['resultId'];
  
    if(!this.resultIds)
    {
      this.redirectTo('/error')
    }
    window.history.replaceState('resultId','');
    this.router.getCurrentNavigation().extras.state['resultId']=''
    this.hideLoader();
  }
  this.gtmService.googleTagManagerId='GTM-M3BV5W63';
  const gtmTag = {
    event: 'bookingSuccess',
    pageName: 'bookingSuccess',

};
//this.gtmService.pushTag(gtmTag);
// this.gtmService.addGtmToDom();
this.gtmService.getDataLayer().push(gtmTag);
}
ngAfterViewInit(){
  window.scrollTo(0,0);

}
redirectTo(path){
  this.router.navigateByUrl(path);
}
hideLoader(){
 
  setTimeout(() => {
   
      $('.preloader').fadeOut();
  }, 50);
}
}
