import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { doc, getFirestore, getDoc, setDoc } from 'firebase/firestore';
import * as CryptoJS from 'crypto-js';
import { SharedModule } from '../shared/Shared.Module';
declare var $: any;

@Component({
  selector: 'app-driver',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './driver.component.html',
  styleUrl: './driver.component.scss'
})
export class DriverComponent implements OnInit{
  status: any;
  data:any;
  keySize = 256;
  ivSize = 128;
  iterations = 100;
  password = "Password@1234";
  @ViewChild('otpContent') otpContent: TemplateRef<any>;
  @ViewChild('phoneContent') phoneContent: TemplateRef<any>;
  otp: any;
  confirmationResult: any ;
  trackingId: any;
  phone:any;
  dbPhone: any;
  verifier: any;
   constructor(private route: ActivatedRoute, private matDialog:MatDialog){
   
   }
    ngOnInit(): void {
      this.route.params.subscribe((res:any)=>{
        // this.state  = res.state;
        let id=this.decrypt(res.trackingId.replaceAll("__","/")).toString(CryptoJS.enc.Utf8)
        // console.log(id);
        this.trackingId = id.toString().split("::")[0];
        this.dbPhone = id.toString().split("::")[2]
        this.tracking();
      })
    }
  tracking(){
    const auth = getAuth();
  
    this.verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      'callback': (response) => {
        // console.log(response)
        
        this.openPhoneDialog();
        this.hideLoader();
      },
      'expired-callback': () => {      
      }
    });
    this.verifier.verify();
   }
   verifyPhone(){
    if(this.phone && this.phone.toString().length==10 && this.dbPhone == this.phone)
    {
      this.sendOTP( this.verifier, "+91"+this.phone)
    }
    else{
      
    }
   }
   openPhoneDialog(){
    const dialogRef = this.matDialog.open(this.phoneContent, {
      disableClose:true,
      backdropClass: 'custom-dialog-overlay',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)      
      {
        
      }
    });
   }
   openOTPDialog(): void {
    
    const dialogRef = this.matDialog.open(this.otpContent, {
      disableClose:true,
      backdropClass: 'custom-dialog-overlay',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)      
      {
        
      }
    });
    
  }
  verify()
  {
    this.matDialog.closeAll();
    $('.preloader').show();
    this.confirmationResult.confirm(this.otp).then(async (res) => {
      // User signed in successfully.
      const user = res.user;
      // console.log(user);
    var result = doc(getFirestore(),"onedropcab.in",this.trackingId);
    var document =await getDoc(result);
    if(document.exists())
    {
      this.data=document.data();
      this.hideLoader();
    }
    else{
      this.hideLoader();
    }
    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      // ...
    });
  }
   sendOTP( recaptchaVerifier, phone){
    try{
      signInWithPhoneNumber(getAuth(), phone, recaptchaVerifier)
      .then(async (confirmationResult) => 
      {
        // console.log(confirmationResult);
        this.confirmationResult = confirmationResult;
        this.openOTPDialog();
        this.hideLoader()
      },
      err=>{
        // console.log(err);
        this.hideLoader()
      }
      
       );
      
    }catch(err){
      // console.error(err);
    }
   }
   decrypt (transitmessage) {
    var salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
    var iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32))
    var encrypted = transitmessage.substring(64);
    
    var key = CryptoJS.PBKDF2(this.password, salt, {
        keySize: this.keySize/32,
        iterations: this.iterations
      });
  
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { 
      iv: iv, 
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
      
    })
    return decrypted;
  }
   hideLoader(){
  
    setTimeout(() => {
        $('.preloader').fadeOut();
    }, 50);
  }
  calc(){
    let additional = this.data.driverBeta+this.data.paidtolls+this.data.parking+this.data.waitingcharges;
    if(this.data.travelType == "One Way")
          {
            this.data.runkmsNum = this.data.runkms;
            if(this.data.runkms<=130)
            {
              this.data.runkmsmin=130;    
              this.data.runkmsNum = 130          
            }
            
          }
          else{
            var dateDiff = this.getDiffDays(this.data.pickupDate, this.data.returnDate)+1;
            
            if(this.data.runkms<=(dateDiff * 250))
            {
              this.data.runkmsmin=(dateDiff * 250);
              this.data.runkmsNum = (dateDiff * 250);
            }
          }
          this.data.finalPrice = this.data.runkmsNum * this.data.pricePerKms+additional
          this.data.commision = ((parseFloat(this.data.runkmsNum) * this.data.pricePerKms) / 10) + 100;
          this.data.status=3;
  }
  getDiffDays(sDate, eDate) {
    var startDate = new Date(sDate);
    var endDate = new Date(eDate);
  
    var Time = endDate.getTime() - startDate.getTime();
    return Time / (1000 * 3600 * 24);
  }
  update(){
    var result = setDoc(doc(getFirestore(),"onedropcab.in", this.trackingId), this.data);
  }
  }